
@keyframes modalEnter {
    from {top: 0}
}

@keyframes modalLeave {
    to {opacity: 0%}
}

@keyframes sideEnter {
    from {left: 0}
}

@keyframes fadeIn {
    from {opacity: 0%}
    to {opacity: 100%}
}

@keyframes fadeOut {
    from {opacity: 100%}
    to {opacity: 0%}
}
